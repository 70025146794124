<template>
    <section class="block-content">
        <h2 class="block-content__title">
            <span class="block-content__title-accent">{{ $t('With Wi-Fi and Ethernet') }}</span>
            {{ $t('Access to the Internet at any time') }}
        </h2>

        <!-- <div class="block-animation block-animation-2" style="text-align: center;">
            <canvas ref="canvas"/>
        </div> -->
        <FrameAnimationBlock
            style="text-align: center;"
            :frameCount="28"
            :getFrameUrl="(i) => require(`@/assets/images/animations/animation2S/${(i).toString().padStart(2, '0')} Animation 2-squashed.jpg`)"
            :maxWidth="610"
            :maxHeight="409"
            :startValue="'15% 25%'"
            :endValueDivider="0.8"
        />
    </section>
</template>
<script>
import FrameAnimationBlock from './children/FrameAnimationBlock.vue';

export default {
    name: 'LndAccess',
    components: {
        FrameAnimationBlock
    }
}
</script>