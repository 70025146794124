<template>
    <section class="big-content section section-characteristic">
        <div class="block-columns section-characteristic__header">
            <div class="block-columns__col-title">
                <h2 class="section__title section-characteristic__title">
                    <span class="text-accent">{{ $t('Technical') }}</span> {{ $t('characteristics') }}
                </h2>
            </div>

            <div class="block-columns__col-content section-characteristic__cards">
                <article class="section-specs__card specs-card">
                    <p class="specs-card__subtitle">{{ $t('lnd.specs.t1') }}</p>
                    <h3 class="specs-card__title">{{ $t('lnd.specs.s2') }}</h3>
                    <p class="specs-card__text">{{ $t('lnd.specs.d1') }}</p>
                </article>
                <article class="section-specs__card specs-card">
                    <p class="specs-card__subtitle">{{ $t('lnd.specs.t2') }}</p>
                    <h3 class="specs-card__title">{{ $t('lnd.specs.s2') }}</h3>
                    <p class="specs-card__text">
                        {{ $t('lnd.specs.d2') }}
                    </p>
                </article>
                <article class="section-specs__card specs-card">
                    <p class="specs-card__subtitle">{{ $t('lnd.specs.t3') }}</p>
                    <h3 class="specs-card__title">{{ $t('lnd.specs.s3') }}</h3>
                    <p class="specs-card__text">
                        {{ $t('lnd.specs.d3') }}
                    </p>
                </article>
                <article class="section-specs__card specs-card">
                    <p class="specs-card__subtitle">{{ $t('lnd.specs.t4') }}</p>
                    <h3 class="specs-card__title">{{ $t('lnd.specs.s4') }}</h3>
                    <p class="specs-card__text">
                        {{ $t('lnd.specs.d4') }}
                    </p>
                </article>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'LndCharacteristics',
}
</script>