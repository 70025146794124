<template>
    <div
        class="block-animation"
        :class="`block-animation-${id}`"
    >
        <canvas ref="canvas" />
    </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
    name: 'LndFrameAnimationBlock',
    props: {
        frameCount: {
            type: Number,
            default: 0
        },
        getFrameUrl: {
            type: Function,
            default: () => ''
        },
        maxWidth: {
            type: Number,
            default: 874
        },
        maxHeight: {
            type: Number,
            default: 820
        },
        timeout: {
            type: Number,
            default: 0
        },
        startValue: {
            type: String,
            default: ''
        },
        endValueDivider: {
            type: Number,
            default: 1
        }
    },
    data: () => ({
        id: Math.random().toString(36).substr(2, 9),
        scrollTriggerInstance: null
    }),
    methods: {
        setCanvasSize() {
            const maxWidth = this.maxWidth;
            const maxHeight = this.maxHeight;

            const canvas = this.$refs.canvas;
            const convasContainer = this.$refs.canvas.parentElement;
            const containerWidth = convasContainer.clientWidth;

            let canvasWidth = 0;
            let canvasHeight = 0;

            if (containerWidth > maxWidth) {
                canvasWidth = maxWidth;
                canvasHeight = maxHeight;
            } else {
                canvasWidth = containerWidth;
                canvasHeight = containerWidth / maxWidth * maxHeight;
            }

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            return {
                width: canvasWidth,
                height: canvasHeight
            };
        },
        drawImageOnCanvas(image) {
            if (!image) {
                return;
            }

            try {
                const ctx = this.$refs.canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight);
            } catch (e) {
                console.error(e, image);
            }
        },
        getAnimationPlacementInViewport(animationNode, animationNodeAnimationStartTriggerLineNodeTopDistance, viewportTopDistanceInPercent) {
            const animationTriggerScrollLine = window.scrollY + (window.innerHeight / 100 * viewportTopDistanceInPercent);
            const animationNodeAnimationStartTriggerLine = (animationNode.getBoundingClientRect().top + window.scrollY) + (animationNode.getBoundingClientRect().height / 100 * animationNodeAnimationStartTriggerLineNodeTopDistance);
            const animationNodeAnimationEndTriggerLine = animationNode.getBoundingClientRect().bottom + window.scrollY;

            if (animationTriggerScrollLine < animationNodeAnimationStartTriggerLine) {
                return 'top';
            } else if (animationTriggerScrollLine > animationNodeAnimationEndTriggerLine) {
                return 'bottom';
            } else {
                return 'middle';
            }
        }
    },
    mounted() {
        let curFrame = 0;

        const urls = new Array(this.frameCount).fill().map((o, i) => this.getFrameUrl(i + 1));
        const images = urls.map((url, i) => {
            const img = new Image();

            img.src = url;
            // i || (img.onload = updateImage)

            return img;
        });

        const updateImage = (self) => {
            let frame = Math.round((this.frameCount - 1) / 100 * (self.progress * 100)) ;

            if (frame !== curFrame && frame > 0 && frame < this.frameCount) {
                this.drawImageOnCanvas(images[Math.round(frame - 1)]);
                curFrame = frame;
            }
        };

        const { height: canvasHeight } = this.setCanvasSize();
        const viewportPlacement = this.getAnimationPlacementInViewport(this.$refs.canvas, 20, 25);

        const drawDefaultImage = (image) => {
            if (image.complete) {
                this.drawImageOnCanvas(image);
            } else {
                image.onload = () => this.drawImageOnCanvas(image);
            }
            this.drawImageOnCanvas(images[0]);
        };

        if (viewportPlacement === 'top') {
            drawDefaultImage(images[0]);
        } else if (viewportPlacement === 'bottom') {
            drawDefaultImage(images[this.frameCount - 1]);
        }

        setTimeout(() => {
            gsap.registerPlugin(ScrollTrigger);
            this.scrollTriggerInstance = gsap.to(`.block-animation-${this.id}`, {
                duration:  21 / 30,
                scrollTrigger: {
                    trigger: `.block-animation-${this.id}`,
                    triggerActions: 'restart none reverse none',
                    start: this.startValue,
                    // end: 'bottom 70%',
                    end: () => `+=${canvasHeight / this.endValueDivider}`,
                    // markers: true,
                    scrub: 1,
                    pin: true, // to stop scrolling while the animation is playing
                    onUpdate: updateImage
                },
            });
        }, this.timeout);
    },
    beforeDestroy() {
        this.scrollTriggerInstance.scrollTrigger.kill();
    },
}
</script>