<template>
    <section class="block-content">
        <h1 class="block-content__title">
            <span class="block-content__title-accent">DexNode</span>
            {{ $t('The future of data storage is here') }}
        </h1>

        <div class="block-content__buttons">
            <button class="button button-pay" type="button" @click="$emit('buy')">
                <span>
                    {{ $t('Buy for 145 USDT') }}
                </span>
            </button>
            <!-- <button class="button-dark button-play" type="button">Смотреть видео</button> -->
        </div>

        <NodeAnimation class="block-content__images"/>

        <!-- <div ref="canvasContainer" class="block-animation block-content__images">
            <canvas ref="canvas" />
        </div> -->

        <div class="entry-content">
            <p>{{ $t('DexNode combines the capabilities of decentralized cloud') }} <span class="text-accent">{{ $t('storage1') }}</span>, {{ $t('high degree') }} <span class="text-accent">{{ $t('security') }}</span> {{ $t('and incredible') }} <span class="text-accent">{{ $t('performance') }}</span> {{ $t('in one device.') }}</p>
            <p>{{ $t('Forget about the risks of hacking and unreliable routers - with DexNode your data is safe.') }}</p>
        </div>
    </section>
</template>
<script>
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import NodeAnimation from './children/NodeAnimation.vue';

export default {
    name: 'LndFuture',
    components: {
        NodeAnimation
    }
    // methods: {
    //     setCanvasSize() {
    //         const maxWidth = 874;
    //         const maxHeight = 820;
    //         const canvas = this.$refs.canvas;
    //         const convasContainer = this.$refs.canvasContainer;
    //         const containerWidth = convasContainer.clientWidth;

    //         let canvasWidth = 0;
    //         let canvasHeight = 0;

    //         if (containerWidth > maxWidth) {
    //             canvasWidth = maxWidth;
    //             canvasHeight = maxHeight;
    //         } else {
    //             canvasWidth = containerWidth;
    //             canvasHeight = containerWidth / maxWidth * maxHeight;
    //         }

    //         canvas.width = canvasWidth;
    //         canvas.height = canvasHeight;

    //         return {
    //             width: canvasWidth,
    //             height: canvasHeight
    //         };
    //     },
    //     drawImageOnCanvas(image) {
    //         const ctx = this.$refs.canvas.getContext("2d");

    //         ctx.drawImage(image, 0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight);
    //     },
    //     getScreenSize() {
    //         return {
    //             width: window.innerWidth,
    //             height: window.innerHeight
    //         };
    //     }
    // },
    // mounted() {
    //     let curFrame = 0;

    //     const frameCount = 21;
    //     const urls = new Array(frameCount).fill().map((o, i) => `/animations/animation1S/${(i + 1).toString().padStart(2, '0')} Animation 1-squashed.jpg`);
    //     const images = urls.map((url, i) => {
    //         const img = new Image();

    //         img.src = url;
    //         i || (img.onload = updateImage)

    //         return img;
    //     });

    //     const updateImage = (self) => {
    //         let frame = Math.round(frameCount / 100 * (self.progress * 100)) ;

    //         if (frame !== curFrame && frame > 0) { // only draw if necessary
    //             this.drawImageOnCanvas(images[Math.round(frame - 1)]);
    //             curFrame = frame;
    //         }
    //     };

    //     const {
    //         width: canvasWidth,
    //         height: canvasHeight
    //     } = this.setCanvasSize();

    //     const {
    //         width: screenWidth,
    //         height: screenHeight
    //     } = this.getScreenSize()

    //     setTimeout(() => this.drawImageOnCanvas(images[0]), 0);

    //     gsap.registerPlugin(ScrollTrigger);
    //     gsap.to('.block-animation', {
    //         duration:  21 / 30,
    //         scrollTrigger: {
    //             trigger: '.block-animation',
    //             triggerActions: 'restart none reverse none',
    //             start: '20% 25%',
    //             // end: 'bottom 70%',
    //             end: () => `+=${canvasHeight / 2}`,
    //             markers: true,
    //             scrub: 1,
    //             pin: true, // to stop scrolling while the animation is playing
    //             onUpdate: updateImage
    //         },
    //     });
    // }
}
</script>
<style scoped>
.block-animation {
    max-width: 874px !important;
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}
</style>