<template>
    <section class="block-content section-frame">
        <h2 class="block-content__title section-frame__title">
            {{ $t('In a truly') }} <span class="block-content__title-accent">{{ $t('compact') }}</span> {{ $t('body') }}
        </h2>

        <img src="@/assets/images/product-size.png" alt="product" class="block-content__img section-frame__img">
    </section>
</template>
<script>
export default {
    name: 'LndSize',
}
</script>