<template>
    <section class="block-content section-control">
        <h2 class="block-content__title section-control__title">
            <span class="block-content__title-accent">DexNode</span>
            {{ $t('Take back control of your data') }}
        </h2>

        <button class="button button-pay section-control__btn" type="button" @click="$emit('buy')">
            <span>
                {{ $t('Buy for 145 USDT') }}
            </span>
        </button>
    </section>
</template>
<script>
export default {
    name: 'LndControl',
}
</script>