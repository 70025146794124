<template>
    <FrameAnimationBlock
        :frameCount="21"
        :getFrameUrl="i => require(`@/assets/images/animations/animation1S/${(i).toString().padStart(2, '0')} Animation 1-squashed.jpg`)"
        :maxWidth="874"
        :maxHeight="820"
        :timeout="timeout"
        :startValue="'15% 25%'"
        :endValueDivider="1.2"
    />
</template>
<script>
import FrameAnimationBlock from './FrameAnimationBlock.vue';

export default {
    name: 'LndNodeAnimation',
    props: {
        timeout: {
            type: Number,
            default: 0
        }
    },
    components: {
        FrameAnimationBlock
    },
}
</script>